

@import url('https://fonts.googleapis.com/css2?family=Bellefair&family=PT+Sans+Narrow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.pt-sans-narrow-regular {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bellefair-regular {
  font-family: "Bellefair", serif;
  font-weight: 400;
  font-style: normal;
}


.pt-sans-narrow-bold {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kanit-thin {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.kanit-extralight {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.kanit-light {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kanit-semibold {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.kanit-bold {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kanit-extrabold {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.kanit-black {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.kanit-thin-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.kanit-extralight-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.kanit-light-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.kanit-regular-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.kanit-medium-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.kanit-semibold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.kanit-bold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.kanit-extrabold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.kanit-black-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: italic;
}


.App {
  text-align: center;
  padding-top: 50px; /* Adjust this value as needed */

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  max-width: 100vw;
}

.Test {
  background-color: #a6a7a8;

}

.Test2 {
  background-color: #526272;

}

.kanitfont{
  font-family: kanit;
}

.ButtonFire {
  background-color: transparent; /* Changed to a more professional shade of green */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding for a better size */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Bold text */
  text-transform: uppercase; /* Uppercase text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  font-family: kanit;
  font-size: 29px;
}

.ButtonFire:hover {
  background-color: transparent; /* Darker shade on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

.ButtonFire:active {
  background-color: transparent; /* Even darker shade when active */
  transform: translateY(0); /* Remove lift on active */
}

.ButtonFire:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(206, 209, 209, 0.5); /* Custom focus outline */
}

.avatar {
  border-radius: 50%;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 107%; /* Position above the image */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  width: 120px;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* Bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://cdn.oneesports.gg/cdn-data/2024/07/TeamfightTactics_Set12_MagicandMayhem_KeyVisual.jpg');
  background-size: cover;
  background-position: center;
  filter: blur(13px);
  z-index: -1;
}

.background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.21); /* Adjust the alpha value to control the darkness */
  z-index: -1; /* Ensure this stays behind the content */
}

.spaced-component {
  margin-bottom: 50px; /* Adjust the value as needed */
}

.divider {
  height: 2px; /* Adjust the height as needed */
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  margin: 50px 0; /* Adjust the margin as needed */
  border: none;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 4 items per row */
  gap: 0px; /* Adjust gap between items */
  

}

.grid-container-mobile {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 items per row */
  gap: 0px; /* Adjust gap between items */
  

}
.grid-traits-container {
  max-width: 100vw; /* Ensure container doesn't exceed viewport width */
  overflow-x: hidden; /* Hide any overflow content horizontally */


  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  gap: 0px; /* Adjust gap between items */
  

}

.grid-traits-container-mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 4 items per row */
  gap: 0px; /* Adjust gap between items */
  max-width: 100vw; /* Ensure container doesn't exceed viewport width */
  overflow-x: hidden; /* Hide any overflow content horizontally */


  

}
.grid-item {
  margin-top: 20px;
  text-align: center; /* Center align content */
  
  
}

.grid-item-highlight {
  margin-top: 20px;
  padding-bottom: 20px;
  padding-top: 20px;

  text-align: center; /* Center align content */

  background-color: rgba(255, 255, 255, 0.2); 
  border: 2px solid rgb(167, 187, 148); /* 2px wide solid border with red color */

  
  
}

.grid-quiz-item {
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;


}

.grid-quiz-item:hover{
  transform: translateY(-2px); /* Slight lift on hover */

}

.grid-quiz-item-selected{
  text-align: center;
  cursor: pointer;
  display:grid;
  margin-left: 150px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.67); /* Subtle shadow for depth */
  background-color: green;

}

.item-image {
  cursor: pointer;
}

.item-image:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.component-container {
  justify-content: center;
  margin-top: 0px;
}

.component-image {
  width: 48px; /* Adjust the size as needed */
  height: 48px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.67); /* Subtle shadow for depth */
  

}

.component-quiz-image {
  width: 48px; /* Adjust the size as needed */
  height: 48px;
  cursor: pointer;
  

}


.component-image:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.champion-image {
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.67); /* Subtle shadow for depth */

}



.champion-image:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.quiz-champion-image-container {
  cursor: pointer;
  padding: 50px;
  overflow: hidden; /* Prevents the image from overflowing the container */
  max-width: 100vw; /* Ensure container doesn't exceed viewport width */
  overflow-x: hidden; /* Hide any overflow content horizontally */


}

.quiz-champion-image-wrapper {
  position: relative;
  max-width: 100vw; /* Ensure container doesn't exceed viewport width */
  overflow-x: hidden; /* Hide any overflow content horizontally */

  /* Adjust border size and color as needed */
}


.quiz-champion-image {
  cursor: pointer;
  transition: transform 0.3s ease; /* Optional: Smooth transition */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.67); /* Subtle shadow for depth */
  display:flex;
  border-bottom: 2px solid rgb(28, 29, 27); /* 2px wide solid 
  border-right: 2px solid rgb(47, 48, 45); /* 2px wide solid  */


}

.quiz-champion-image-shake{
  cursor: pointer;
  transition: transform 0.3s ease; /* Optional: Smooth transition */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.67); /* Subtle shadow for depth */
  display:flex;
  animation: shake 0.5s;

}



@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.quiz-champion-image-text{
  display:flex;
}

.quiz-champion-image:hover {
  transform:  translateY(-2px); /* Apply a slight lift on hover */

}

::-webkit-scrollbar{
  width: 0;
}

.scrollPath{
  position: fixed;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
}

.progressbar{
  position: fixed;
  top: 0;
  right: 0;
  width: 6px; 
  height: 20%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  animation: animate 5s linear infinite;
}

@keyframes animate{
  0%, 100%
  {
    filter:hue-rotate(0deg);
  }
  50%
  {
    filter:hue-rotate(360deg);
  }
}

.progressbar:before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; 
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  filter: blur(10px);

}

.progressbar:after{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; 
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  filter: blur(30px);

}



.quiz-trait-image{
  cursor: pointer;
}



.trait-text {
  display: flex;
  color: black;
  margin: 0; /* Remove default margins */
  font-size: 20px; /* Adjust font size as needed */
      font-weight: 600;
      font-family: kanit;
}

.trait-item {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  padding: 5px; /* Reduced padding */
  


}

.trait-item-shake {
  display: flex;
  align-items: center;
  padding: 10px;
  animation: shake 0.5s;
  justify-content: center;
}

.trait-item-selected {
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 2.97); /* Subtle shadow for depth */
  border: 2px solid rgb(156, 214, 101); /* 2px wide solid border with red color */
  justify-content: center;

}

.quiz-trait-item{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: center;
}

.quiz-champion-name{
  display: flex;
  color: black;
  margin-left: 10px; /* Remove default margins */
  font-size: 45px; /* Adjust font size as needed */
      font-weight: 600;
      font-family: kanit;
}

.accuracy-text-container{
  display:inline-block;
    max-width: 100vw; /* Ensure container doesn't exceed viewport width */
    overflow-x: hidden; /* Hide any overflow content horizontally */


}

.accuracy-text{
  font-size: 25px; /* Adjust font size as needed */
  margin-right: 5%;

      font-weight: 600;
      font-family: kanit;
      transform: scale(0.5);

}

.accuracy-text-mobile{
  max-width: 100vw; /* Ensure container doesn't exceed viewport width */
  overflow-x: hidden; /* Hide any overflow content horizontally */

  font-size: 19px; /* Adjust font size as needed */
  margin-right: 5%;
      font-weight: 600;
      font-family: kanit;
      transform: scale(0.5);

}


#accuracy {
  position: absolute;
  top: 5%; /* Move to the vertical center */
  left: 87%; /* Move to the horizontal center */
  transform: translate(-50%, -50%); /* Offset by 50% of its own dimensions to center it */
  opacity: 0.65; /* 50% opacity, adjust as needed */

}

#success {
  position: absolute;
  top: 5%; /* Move to the vertical center */
  left: 78%; /* Move to the horizontal center */
  transform: translate(-50%, -50%); /* Offset by 50% of its own dimensions to center it */
  opacity: 0.65; /* 50% opacity, adjust as needed */
  
  

}


/* AboutPage component styles */
.about-container {
  background-color: rgba(0, 0, 0, 0.4); /* Dark background */
  color: white; /* Light text color for contrast */
  padding: 20px;
  border-radius: 8px;
  max-width: 70vw;
  margin: 40px auto; /* Centered with margin */
  text-align: center; /* Centered text */
  overflow-x: hidden;
}

.about-container h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.about-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 10px 0;
}

.about-container a {
  color: #a3c93c; /* DodgerBlue color for the link */
  text-decoration: none; /* Remove underline */
}

.about-container a:hover {
  text-decoration: underline; /* Underline on hover */
}






